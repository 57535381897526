// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// react-flatpickr components
import Flatpickr from "react-flatpickr";

// react-flatpickr styles
import "flatpickr/dist/flatpickr.css";
import { english as en } from "flatpickr/dist/l10n/default";
import { Lithuanian as lt } from "flatpickr/dist/l10n/lt";

// Material Dashboard 2 PRO React components
import MDInput from "components/MDInput";
import { useLocale } from "../../useLocale";

const locales = { en, lt };

function MDDatePicker({ input = {}, ...rest }) {
    const { locale } = useLocale();
    return (
        <Flatpickr
            {...rest}
            options={{ locale: locales[locale] }}
            render={({ defaultValue }, ref) => (
                <MDInput {...input} defaultValue={defaultValue} inputRef={ref} />
            )}
        />
    );
}

// Typechecking props for the MDDatePicker
MDDatePicker.propTypes = {
    input: PropTypes.objectOf(PropTypes.any),
};

export default MDDatePicker;
