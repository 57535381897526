import { getCookie } from './cookies'

export const apiURLMap = {
  development: 'https://dev.spotya.lt/api',
  production: '/api',
  local: 'http://localhost:8000/api',
}

export const citiesArray = [
  {
    "label": "Vilnius",
    "value": "Vilnius",
    "coordinates": {
      "lat": 54.6872,
      "lng": 25.2797
    },
    "zoom": 13
  },
  {
    "label": "Kaunas",
    "value": "Kaunas",
    "coordinates": {
      "lat": 54.8985,
      "lng": 23.9036
    },
    "zoom": 13
  },
  {
    "label": "Klaipėda",
    "value": "Klaipėda",
    "coordinates": {
      "lat": 55.7033,
      "lng": 21.1443
    },
    "zoom": 13
  },
  {
    "label": "Šiauliai",
    "value": "Šiauliai",
    "coordinates": {
      "lat": 55.9333,
      "lng": 23.3167
    },
    "zoom": 13
  },
  {
    "label": "Panevėžys",
    "value": "Panevėžys",
    "coordinates": {
      "lat": 55.7333,
      "lng": 24.3500
    },
    "zoom": 13
  },
  {
    "label": "Alytus",
    "value": "Alytus",
    "coordinates": {
      "lat": 54.3964,
      "lng": 24.0415
    },
    "zoom": 13
  },
  {
    "label": "Birštonas",
    "value": "Birštonas",
    "coordinates": {
      "lat": 54.6167,
      "lng": 24.0333
    },
    "zoom": 14
  },
  {
    "label": "Druskininkai",
    "value": "Druskininkai",
    "coordinates": {
      "lat": 54.0200,
      "lng": 23.9700
    },
    "zoom": 14
  },
  {
    "label": "Gargždai",
    "value": "Gargždai",
    "coordinates": {
      "lat": 55.7123,
      "lng": 21.4081
    },
    "zoom": 14
  },
  {
    "label": "Jonava",
    "value": "Jonava",
    "coordinates": {
      "lat": 55.0833,
      "lng": 24.2800
    },
    "zoom": 14
  },
  {
    "label": "Joniškis",
    "value": "Joniškis",
    "coordinates": {
      "lat": 56.2403,
      "lng": 23.6150
    },
    "zoom": 14
  },
  {
    "label": "Jurbarkas",
    "value": "Jurbarkas",
    "coordinates": {
      "lat": 55.0762,
      "lng": 22.7699
    },
    "zoom": 14
  },
  {
    "label": "Kėdainiai",
    "value": "Kėdainiai",
    "coordinates": {
      "lat": 55.2886,
      "lng": 23.9681
    },
    "zoom": 14
  },
  {
    "label": "Kretinga",
    "value": "Kretinga",
    "coordinates": {
      "lat": 55.8753,
      "lng": 21.2447
    },
    "zoom": 14
  },
  {
    "label": "Marijampolė",
    "value": "Marijampolė",
    "coordinates": {
      "lat": 54.5622,
      "lng": 23.3497
    },
    "zoom": 14
  },
  {
    "label": "Mažeikiai",
    "value": "Mažeikiai",
    "coordinates": {
      "lat": 56.3167,
      "lng": 22.3333
    },
    "zoom": 14
  },
  {
    "label": "Molėtai",
    "value": "Molėtai",
    "coordinates": {
      "lat": 55.2333,
      "lng": 25.4167
    },
    "zoom": 14
  },
  {
    "label": "Palanga",
    "value": "Palanga",
    "coordinates": {
      "lat": 55.9200,
      "lng": 21.0600
    },
    "zoom": 14
  },
  {
    "label": "Plungė",
    "value": "Plungė",
    "coordinates": {
      "lat": 55.9111,
      "lng": 21.8458
    },
    "zoom": 14
  },
  {
    "label": "Prienai",
    "value": "Prienai",
    "coordinates": {
      "lat": 54.6333,
      "lng": 23.9500
    },
    "zoom": 14
  },
  {
    "label": "Radviliškis",
    "value": "Radviliškis",
    "coordinates": {
      "lat": 55.8167,
      "lng": 23.5333
    },
    "zoom": 14
  },
  {
    "label": "Raseiniai",
    "value": "Raseiniai",
    "coordinates": {
      "lat": 55.3733,
      "lng": 23.1200
    },
    "zoom": 14
  },
  {
    "label": "Rokiškis",
    "value": "Rokiškis",
    "coordinates": {
      "lat": 55.9631,
      "lng": 25.5956
    },
    "zoom": 14
  },
  {
    "label": "Šakiai",
    "value": "Šakiai",
    "coordinates": {
      "lat": 54.9543,
      "lng": 23.0387
    },
    "zoom": 14
  },
  {
    "label": "Šilalė",
    "value": "Šilalė",
    "coordinates": {
      "lat": 55.4918,
      "lng": 22.1823
    },
    "zoom": 14
  },
  {
    "label": "Šilutė",
    "value": "Šilutė",
    "coordinates": {
      "lat": 55.3483,
      "lng": 21.4806
    },
    "zoom": 14
  },
  {
    "label": "Tauragė",
    "value": "Tauragė",
    "coordinates": {
      "lat": 55.2544,
      "lng": 22.2897
    },
    "zoom": 14
  },
  {
    "label": "Telšiai",
    "value": "Telšiai",
    "coordinates": {
      "lat": 55.9811,
      "lng": 22.2472
    },
    "zoom": 14
  },
  {
    "label": "Ukmergė",
    "value": "Ukmergė",
    "coordinates": {
      "lat": 55.2500,
      "lng": 24.7500
    },
    "zoom": 14
  },
  {
    "label": "Utena",
    "value": "Utena",
    "coordinates": {
      "lat": 55.5000,
      "lng": 25.6000
    },
    "zoom": 14
  }
]

export const sleep = (timeout) => new Promise((r) => setTimeout(r, timeout));

export class API {
  get baseURL() {
    if(!process.env.REACT_APP_ENV) {
      process.env.REACT_APP_ENV = process.env.NODE_ENV
    }
    return apiURLMap[process.env.REACT_APP_ENV] || apiURLMap.production//process.env.NODE_ENV==='development'?`https://dev.spotya.lt/api`:`/api`
  }

  async put(path, body) {
    try {
      return await fetch(`${this.baseURL}/${path}`, {
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${getCookie('d-token')}`,
        },
        body: JSON.stringify(body)
      }).then(r => r.json())
    } catch (e) {
      console.log(e)
    }
  }

  async post(path, body) {
    return await fetch( `${this.baseURL}/${path}`,{
      method:"POST",
      headers: {
        Accept:"application/json",
        "Content-Type":"application/json",
        Authorization: `Bearer ${getCookie('d-token')}`,
      },
      body: JSON.stringify(body)}).then(r=>r.json())
  }

  async upload(path, file) {
    const data = new FormData()
    data.append('file', file)
    return await fetch( `${this.baseURL}/${path}`,{
      method:"POST",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${getCookie('d-token')}`,
      },
      body: data}).then(r=>r.json())
  }

  async get(path, query = {}) {
    try {
      return await fetch(`${this.baseURL}/${path}${Object.keys(query).length>0?`?${Object.keys(query).sort().map(k => `${encodeURIComponent(k)}=${encodeURIComponent(query[k])}`).join('&').replace(/[^\x00-\x7F]/g, "0")}`:''}`,{
        method:"GET",
        headers: {
          Accept:"application/json",
          "Content-Type":"application/json",
          Authorization: `Bearer ${getCookie('d-token')}`
        }}).then(r=>r.json())
    } catch (e) {
      console.log(e)
    }
  }
  async getFile(path, name) {
    try {
      return await fetch(`${this.baseURL}/${path}`,{
        method:"GET",
        headers: {
          Accept:"application/json",
          "Content-Type":"application/json",
          Authorization: `Bearer ${getCookie('d-token')}`
        }}).then( res => res.blob() )
    } catch (e) {
      console.log(e)
    }
  }
  async delete(path) {
    return await fetch(`${this.baseURL}/${path}`,{
      method:"DELETE",
      headers: {
        Accept:"application/json",
        "Content-Type":"application/json",
        Authorization: `Bearer ${getCookie('d-token')}`
      }}).then(r=>r.json())
  }

}
