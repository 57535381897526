/*!

=========================================================
* Paper Kit React - v1.3.2
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2023 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, {useCallback, useState} from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Navigate, Routes, useLocation } from "react-router-dom";

// styles
import "App.css";
import "bootstrap/scss/bootstrap.scss";
import "assets/scss/paper-kit.scss?v=1.3.0";
import "assets/demo/demo.css?v=1.3.0";
// pages
import TestimonialsPage from "views/examples/TestimonialsPage";
import AboutPage from "views/examples/AboutPage";
import IndexPage from "views/examples/IndexPage";
import Terms from "views/examples/Terms";
import Policy from "views/examples/Policy";




import ClientLoginPage from "views/examples/auth/ClientLoginPage.js";
import ClientSignupPage from "views/examples/auth/ClientSignupPage.js";
import CoachLoginPage from "views/examples/auth/CoachLoginPage.js";
import CoachSignupPage from "views/examples/auth/CoachSignupPage.js";
import ResetPasswordPage from "views/examples/auth/ResetPasswordPage.js";
import SearchPage from "views/examples/SearchPage.js";
import CssBaseline from "@mui/material/CssBaseline";
import {ThemeProvider} from "@mui/material/styles";

import ClientProfilePage from "views/examples/client/ProfilePage.js";
import ClientBookingsPage from "views/examples/client/BookingsPage.js";
import ClientBookingFormPage from "views/examples/client/CalendarPage.js";
import ClientPricingPage from "views/examples/pricing-page/index.js";
import ClientCheckoutPage from "views/examples/pricing-page/Checkout.js";

import CoachProfilePage from "views/examples/coach/ProfilePage.js";
import CoachProfileViewPage from "views/examples/coach/ProfileViewPage.js";
import CoachSchedulePage from "views/examples/coach/CoachSchedulePage.js";
import CoachScheduleEditorPage from "views/examples/coach/CalendarPage.js";
import CoachCalendarPage from "views/examples/coach/CalendarPage.js";

// others
import theme from "assets/theme";
import {MaterialUIControllerProvider} from "context";
import SearchIcon from "@mui/icons-material/Search";

import {useAuth, ProvideAuth} from "./useAuth";
import LoginIcon from "@mui/icons-material/Login";



import {IntlProvider} from 'react-intl'
import {useLocale, ProvideLocale, appLocales} from "./useLocale";
import {CoachScheduleEditor} from "./views/examples/coach/CoachScheduleEditor";


const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
    <MaterialUIControllerProvider>
        <ProvideLocale>
            <ProvideAuth>
                <App/>
            </ProvideAuth>
        </ProvideLocale>
    </MaterialUIControllerProvider>
);

const PrivateRoute = ({children, ...rest}) => {
    let location = useLocation();
    const {user} = useAuth();
    if(user?.isBusiness && user.businessLevel < (typeof(rest.route.businessLevelMin) !== 'undefined' ? rest.route.businessLevelMin : 1) && rest.route.route!=='/business/pricing'){
        return (<Navigate
            to={{
                pathname: "/business/pricing",
                from: location
            }}
        />)
    }
    return (
        user!==false ? (
            children
    ) : (
          <Navigate
              to={{
                pathname: "/client/login",
                from: location
              }}
          />
        )
    );
}

const routes = [

    {
        type: "collapse",
        name: "Index",
        key: "index",
        isPrivate: false,
        icon: <SearchIcon fontSize={'medium'}/>,
        route: "/index",
        component: <IndexPage />,
    },

     {
        type: "collapse",
        name: "About",
        key: "about",
        isPrivate: false,
        icon: null,
        route: "/about",
        component: <AboutPage />,
    },

     {
        type: "collapse",
        name: "Testimonials",
        key: "testimonials",
        isPrivate: false,
        icon: null,
        route: "/testimonials",
        component: <TestimonialsPage />,
    },

    {
        type: "collapse",
        name: "Rasti trenerį",
        key: "search",
        isPrivate: false,
        icon: <SearchIcon fontSize={'medium'}/>,
        route: "/search",
        component: <SearchPage />,
    },


    {
        type: "collapse",
        name: "Client Signup",
        key: "client.signup",
        isPrivate: false,
        icon: <LoginIcon fontSize={'medium'}/>,
        route: "/client/signup",
        component: <ClientSignupPage />,
    },
    {
        type: "collapse",
        name: "Client Login",
        key: "client.login",
        isPrivate: false,
        icon: <LoginIcon fontSize={'medium'}/>,
        route: "/client/login",
        component: <ClientLoginPage />,
    },
    {
        type: "collapse",
        name: "Reset Password",
        key: "reset.password",
        isPrivate: false,
        route: "/auth/reset",
        component: <ResetPasswordPage />,
    },
    {
        type: "collapse",
        name: "Coach Signup",
        key: "coach.signup",
        isPrivate: false,
        icon: <LoginIcon fontSize={'medium'}/>,
        route: "/business/signup",
        component: <CoachSignupPage />,
    },
    {
        type: "collapse",
        name: "Coach Login",
        key: "coach.login",
        isPrivate: false,
        icon: <LoginIcon fontSize={'medium'}/>,
        route: "/business/login",
        component: <CoachLoginPage />,
    },
    {
        type: "collapse",
        name: "Client Profile",
        key: "client.profile",
        isPrivate: true,
        clientAccess: true,
        icon: <LoginIcon fontSize={'medium'}/>,
        route: "/client/profile",
        component: <ClientProfilePage />,
    },
    {
        type: "collapse",
        name: "Client Bookings",
        key: "client.bookings",
        isPrivate: true,
        clientAccess: true,
        icon: <LoginIcon fontSize={'medium'}/>,
        route: "/client/bookings",
        component: <ClientBookingsPage />,
    },
    {
        type: "collapse",
        name: "Client Booking Form",
        key: "client.bookingform",
        isPrivate: true,
        clientAccess: true,
        icon: <LoginIcon fontSize={'medium'}/>,
        route: "/client/booking-form",
        component: <ClientBookingFormPage />,
    },
    {
        type: "collapse",
        name: "Coach Pricing",
        key: "coach.pricing",
        isPrivate: true,
        businessAccess: true,
        businessLevelMin: 0,
        icon: <LoginIcon fontSize={'medium'}/>,
        route: "/business/pricing",
        component: <ClientPricingPage />,
    },
    {
        type: "collapse",
        name: "Coach Checkout",
        key: "coach.checkout",
        isPrivate: true,
        businessAccess: true,
        businessLevelMin: 0,
        icon: <LoginIcon fontSize={'medium'}/>,
        route: "/business/checkout/:plan",
        component: <ClientCheckoutPage />,
    },
    {
        type: "collapse",
        name: "Coach Profile",
        key: "coach.profile",
        isPrivate: true,
        businessAccess: true,
        icon: <LoginIcon fontSize={'medium'}/>,
        route: "/business/profile",
        component: <CoachProfilePage />,
    },
    {
        type: "collapse",
        name: "Coach Schedule",
        key: "coach.schedule",
        isPrivate: true,
        businessAccess: true,
        icon: <LoginIcon fontSize={'medium'}/>,
        route: "/business/schedule",
        component: <CoachScheduleEditor />,
    },
    {
        type: "collapse",
        name: "Coach Schedule Form",
        key: "coach.schedule-editor",
        isPrivate: true,
        businessAccess: true,
        icon: <LoginIcon fontSize={'medium'}/>,
        route: "/business/schedule-editor",
        component: <CoachScheduleEditorPage />,
    },
    {
        type: "collapse",
        name: "Coach Calendar",
        key: "coach.calendar",
        isPrivate: true,
        businessAccess: true,
        icon: <LoginIcon fontSize={'medium'}/>,
        route: "/business/calendar",
        component: <CoachCalendarPage />,
    },
    {
        type: "collapse",
        name: "Coach Profile View",
        key: "coach-profile",
        isPrivate: false,
        icon: <LoginIcon fontSize={'medium'}/>,
        route: "/coach-profile/:id/:tab?/:tab2?",
        component: <CoachProfileViewPage />,
    },

];

function App() {
    const {user} = useAuth();
    const {locale} = useLocale();

    if(user) {
        document.body.classList.add("authorized");
        if(user.isBusiness) {
            document.body.classList.add("authorized-coach");
        }
    }
    const getRoutes = useCallback((allRoutes) => {
        return [...allRoutes.map((route) => {
            if (route.collapse) {
                return getRoutes(route.collapse);
            }
            if (route.route) {
                if (route.isPrivate) {
                    if(route.businessAccess && !user.isBusiness){
                        return  <Route path={route.route} element={<Navigate to="/search" />}  key={route.key} />;
                    }
                    return (
                        <Route path={route.route} element={
                            <PrivateRoute user={user} route={route}>
                                {route.component}
                            </PrivateRoute>
                        } key={route.key}/>
                    );
                }
                return <Route key={route.key} path={route.route} element={route.component} />;
            }

            return null;
        }),
            <Route key={'terms'} path="/terms" element={<Terms/>} />,
            <Route key={'policy'} path="/policy" element={<Policy/>} />,
            <Route key={'root_route'} path="*" element={(!user || !user.isBusiness)?(<Navigate to="/search" replace />):(<Navigate to="/search" replace />)} />];
    },[user]);


    return <ThemeProvider theme={theme}>
        <IntlProvider messages={appLocales[locale]} locale={locale} defaultLocale="en">
            <CssBaseline/>
            <BrowserRouter>
                <Routes>
                    {getRoutes(routes)}
                </Routes>
            </BrowserRouter>
        </IntlProvider>
    </ThemeProvider>;
}
