/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.2.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2023 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

import {useCallback, useState} from "react";

// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

import VerificationInput from "react-verification-input";

// Authentication layout components
import CoverLayout from "./components/CoverLayout";
import {useAuth} from "../../../useAuth";

// Images
import bgImage from "assets/images/26727090.jpg";
import Swal2 from "sweetalert2";
import Swal from "sweetalert2";
import {FormattedMessage, useIntl} from "react-intl";

function Cover() {
    const intl = useIntl()
    const [sent, setSent] = useState(false);
    const [email, setEmail] = useState(null);
    const [phone, setPhone] = useState(null);
    const [code, setCode] = useState(null);
    const [password, setPassword] = useState(null);
    const [showResend, setShowResend] = useState(false);

    const auth = useAuth();
    const urlParams = new URLSearchParams(window.location.search);
    const redirectCB = useCallback((user) => {
        document.location.href = (urlParams.get('from') || (user.isBusiness ? '/profile' : '/search'))
    }, [auth.user])

    return (
        <CoverLayout image={bgImage}>
            <Card>
                <MDBox
                    variant="gradient"
                    bgColor="info"
                    borderRadius="lg"
                    coloredShadow="success"
                    mx={2}
                    mt={-3}
                    p={3}
                    mb={1}
                    textAlign="center"
                >
                    <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
                        <FormattedMessage id={"common.signin"}/>
                    </MDTypography>
                    <MDTypography display="block" variant="button" color="white" my={1}>
                        <FormattedMessage id={"pages.login.enterEmail"}/>
                    </MDTypography>
                </MDBox>
                <MDBox pt={4} pb={3} px={3}>
                    <MDBox component="form" role="form">
                        <MDBox mb={2}>
                            <MDInput
                                type="text"
                                name='email'
                                label={intl.formatMessage({id: "common.email"})}
                                variant="standard"
                                fullWidth
                                InputLabelProps={{ shrink: true }}
                                onChange={e=> {
                                    if(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(e.target.value)) {
                                        setEmail(e.target.value)
                                        setPhone(null)
                                    }  else {
                                        setPhone(null)
                                        setEmail(null)
                                    }
                                }}
                            />
                        </MDBox>
                        <MDBox mb={2}>
                            <MDInput disabled={sent}
                                     fullWidth
                                     label={intl.formatMessage({id: "common.password"})}
                                     variant="standard"
                                     value={password}
                                     name={'pw1'}
                                     onChange={e => setPassword(e.target.value)}
                                     inputProps={{type: "password"}}
                            />
                        </MDBox>
                        <MDBox mb={2}>
                            {(phone || email) && sent && <div style={{width: '100%', display: 'flex', justifyContent: 'center', overflowX: 'auto', overflowY: 'hidden'}}>
                                <VerificationInput validChars={'0-9'} onChange={setCode}/>
                            </div>}
                        </MDBox>
                        {sent && <MDBox mt={4} mb={1}>
                            <MDButton variant="gradient" color="success" fullWidth onClick={async ()=> {
                                if(code) {
                                    setSent(true);
                                    auth.signin(email, phone, code, redirectCB)
                                } else {
                                    Swal2.fire({
                                        position: "top-end",
                                        icon: "error",
                                        title: "Code is required",
                                        text: "Please enter the code or try resending it in 60 seconds again",
                                        showConfirmButton: false,
                                        timer: 1500
                                    });
                                }
                            }}>
                                Verify
                            </MDButton>
                        </MDBox>}
                        <MDButton variant="gradient" color="info" disabled={!email} fullWidth onClick={async ()=> {
                            if(email && password) {
                                auth.login(email, password, redirectCB)
                            } else {
                                Swal.fire({
                                    title: '',
                                    text: `Please fill in both Email and Password fields`,
                                    icon: 'warning',
                                    confirmButtonText: 'OK'
                                })
                            }
                        }}><FormattedMessage id={"common.login"}/></MDButton>
                        {(!sent || showResend) && false && (<MDBox mt={4} mb={1}>
                            <MDButton variant="gradient" color="info" disabled={!email && !phone} fullWidth onClick={async ()=> {
                                if(email || phone) {
                                    setShowResend(false);
                                    await auth.sendCode({email, phone}, (res)=>{
                                        if(res) {
                                            setSent(true);
                                            setTimeout(() => setShowResend(true), 10000)
                                        }
                                    })
                                }
                            }}>
                        {showResend?'Resend OTP':'Send OTP'}
                            </MDButton>
                        </MDBox>)}
                        <MDBox mt={3} mb={1} textAlign="center">
                            <MDTypography variant="button" color="text">
                                <FormattedMessage id={"pages.login.dontHave"}/>{" "}
                                <MDTypography
                                    component={Link}
                                    to="/client/signup"
                                    variant="button"
                                    color="info"
                                    fontWeight="medium"
                                    textGradient
                                >
                                    <FormattedMessage id={"common.signup"}/>
                                </MDTypography>
                            </MDTypography>
                        </MDBox>
                        <MDBox mt={1} mb={1} textAlign="center">
                            <MDTypography variant="button" color="text">
                                <FormattedMessage id={"pages.login.forgot"}/>{" "}
                                <MDTypography
                                    component={Link}
                                    variant="button"
                                    color="info"
                                    fontWeight="medium"
                                    textGradient
                                    onClick={()=>{
                                        if(email) {
                                            auth.sendPasswordResetEmail(email)
                                        } else {
                                            Swal.fire({
                                                title: '',
                                                text: `Please fill in Email field`,
                                                icon: 'warning',
                                                confirmButtonText: 'OK'
                                            })
                                        }
                                    }}
                                >
                                    <FormattedMessage id={"common.reset"}/>
                                </MDTypography>
                            </MDTypography>
                        </MDBox>
                    </MDBox>
                </MDBox>
            </Card>
        </CoverLayout>
    );
}

export default Cover;
