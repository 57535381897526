import { forwardRef } from "react";
import PropTypes from "prop-types";
import MDButtonRoot from "components/MDButton/MDButtonRoot";
import { useMaterialUIController } from "context";

const MDButton = forwardRef(
    (
        {
          size = "medium",
          variant = "contained",
          color = "white",
          circular = false,
          iconOnly = false,
          children,
          ...rest
        },
        ref
    ) => {
      const [controller] = useMaterialUIController();
      const { darkMode } = controller;

      return (
          <MDButtonRoot
              {...rest}
              ref={ref}
              color="primary"
              variant={variant === "gradient" ? "contained" : variant}
              size={size}
              ownerState={{ color, variant, size, circular, iconOnly, darkMode }}
          >
            {children}
          </MDButtonRoot>
      );
    }
);

// Typechecking props for the MDButton
MDButton.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),
  variant: PropTypes.oneOf(["text", "contained", "outlined", "gradient"]),
  color: PropTypes.oneOf([
    "white",
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "light",
    "dark",
    "violet",
  ]),
  circular: PropTypes.bool,
  iconOnly: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

export default MDButton;
