import React, {forwardRef, useCallback, useEffect, useMemo, useState} from "react";
import {
    Box,
    Typography,
    Stack,
    Checkbox
} from "@mui/material";
import {Add, CopyAll, Delete, Save, ContentCopy, PlaylistAdd, ViewList, UnfoldLess} from "@mui/icons-material";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFnsV3";
import {LocalizationProvider, TimePicker} from "@mui/x-date-pickers";
import MDBox from "../../components/MDBox";
import Swal from "sweetalert2";
import {Col, Row} from "reactstrap";
import {FormattedMessage, useIntl} from "react-intl";
import FormField from "./account/components/FormField";
import {useAuth} from "../../useAuth";
import cloneDeep from "lodash/cloneDeep";
import range from "lodash/range";
import {API} from "../../api";
import moment from "moment";
import {v4} from "uuid";
import Select from "react-select";
import Table from "@mui/material/Table";
import MDButton from "../../components/MDButton";
import CheckBox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import withReactContent from "sweetalert2-react-content";
import {EditorState} from "draft-js";
import {Editor} from "react-draft-wysiwyg";
import {convertToHTML, convertFromHTML} from "draft-convert";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import List from "@mui/material/List";
import ListItemText from "@mui/material/ListItemText";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import {MDDialog} from "../../components/Dialog";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import TextField from "@mui/material/TextField";
import MDBadge from "../../components/MDBadge";


const MySwal = withReactContent(Swal);

const CommentTextField = ({value = "<p></p>", day, index, handleFieldChange}) => {


    const EditorComponent = () => {
        const [convertedContent, setConvertedContent] = React.useState(value);
        const [editorState, setEditorState] = React.useState(EditorState.createWithContent(convertFromHTML(value)));
        React.useEffect(() => {
            if (value) {
                const html = convertFromHTML(value);
                if (html && !convertedContent) {
                    setEditorState(EditorState.createWithContent(html))
                    setConvertedContent(html);
                }
            }
        }, [value, convertedContent]);
        React.useEffect(() => {
            let html = convertToHTML(editorState.getCurrentContent());
            handleFieldChange('comment', html, day, index)
        }, [editorState]);
        return (
            (<div className={'swal2-draft'}>
                <Editor editorState={editorState} onEditorStateChange={setEditorState} toolbar={{
                    inline: {inDropdown: true},
                    options: ['inline', 'fontSize', 'list', 'textAlign', 'emoji'],
                }}/>
            </div>)
        )
    }

    const handleCommentClick = () => {
        MySwal.fire({
            width: '50%',
            heightAuto: true,
            title: "Edit Comment",
            showCancelButton: false,
            confirmButtonText: "Close",
            cancelButtonText: "Cancel",
            focusConfirm: false,
            customClass: {
                title: 'swal2-title-weekly',
                popup: 'swal2-popup', // you can customize further as per need
                input: 'swal2-textarea' // class to style textarea
            },
            html: <EditorComponent/>,
            preConfirm: (value) => {
            },
        });
    };
    const intl = useIntl();
    return (<div onClick={handleCommentClick}><label className={'outlined-label'}
                                                     style={{marginTop: 3}}>{intl.formatMessage({id: "common.comment"})}</label>
            <div style={{position: 'relative', height: 44, borderRadius: 6, marginTop: 8}}
                 className={'MuiOutlinedInput-notchedOutline css-135f4hd-MuiOutlinedInput-notchedOutline weekly-responsive-comment-input'}>
                <div dangerouslySetInnerHTML={{__html: value}}></div>
            </div>
        </div>
    );
};


const Toast = Swal.mixin({
    toast: true,
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
        toast.onmouseenter = Swal.stopTimer;
        toast.onmouseleave = Swal.resumeTimer;
    }
});

const api = new API()

const days = ["MON", "TUE", "WED", "THU", "FRI", "SAT", "SUN"];

const availabilityTemplate = (day) => {
    const uuid = v4();

    return {
        start: undefined,
        end: undefined,
        startTime: '09:00',
        endTime: '10:00',
        activity: undefined,
        comment: '',
        location: undefined,
        maxUsers: 1,
        eventId: uuid,
        id: uuid,
        groupId: uuid,
        uuid
    }
}

const initialAvailability = {
    MON: {
        weekDay: 1,
        day: 'MON',
        enabled: true,
        slots: [
            //cloneDeep(availabilityTemplate('MON'))
        ]
    },
    TUE: {
        weekDay: 2,
        day: 'TUE',
        enabled: true,
        slots: []
    },
    WED: {
        weekDay: 3,
        day: 'WED',
        enabled: true,
        slots: []
    },
    THU: {
        weekDay: 4,
        day: 'THU',
        enabled: true,
        slots: []
    },
    FRI: {
        weekDay: 5,
        day: 'FRI',
        enabled: true,
        slots: []
    },
    SAT: {
        weekDay: 6,
        day: 'SAT',
        enabled: false,
        slots: []
    },
    SUN: {
        weekDay: 0,
        day: 'SUN',
        enabled: false,
        slots: []
    },
}
const CloneComponent = ({slot, setCloneTimeRange}) => {
    const slotData = cloneDeep(slot);
    const [startTime, setStartTime] = useState(moment(slotData.endTime, "HH:mm"));
    const [endTime, setEndTime] = useState(moment(moment(slotData.endTime, "HH:mm").add(1, 'hour')));
    const durationInMinutes = useMemo(() => endTime.diff(startTime, 'minutes'), [slot]);

    // Handle start time change
    const handleStartTimeChange = (newValue) => {
        const slotDataTemp = cloneDeep(slotData);
        if (newValue && moment(newValue).hour() >= 6 && moment(newValue).hour() <= 24) {
            setStartTime(moment(newValue));
        } else {
            Toast.fire('warning', 'Start time cannot be earlier than 06:00 and past 23:00');
            setStartTime(moment(slotDataTemp.endTime, "HH:mm"));
            setEndTime(moment(slotDataTemp.endTime, "HH:mm").add(durationInMinutes, 'minutes'));
        }
    };

    // Handle end time change
    const handleEndTimeChange = (newValue) => {
        const slotDataTemp = cloneDeep(slotData);
        if (newValue && moment(newValue).hour() >= 6 && moment(newValue).hour() <= 24) {
            setEndTime(moment(newValue));
        } else {
            Toast.fire('warning', 'End time cannot be earlier than 06:00 and past 23:00');
            setStartTime(moment(slotDataTemp.endTime, "HH:mm"));
            setEndTime(moment(slotDataTemp.endTime, "HH:mm").add(durationInMinutes, 'minutes'));
        }
    };

    // Validation for the range (start must be before end)
    const isRangeValid = useMemo(() => {
        setCloneTimeRange({startTime, endTime});
        return startTime && endTime && startTime.isBefore(endTime);
    }, [startTime, endTime])


    return (<div className={'text-center'}>
        <h4>Select time range to fill it with cloned slots</h4>
        <div style={{
            justifyContent: 'center',
            width: '100%',
            display: 'flex',
            gap: '20px',
            alignItems: 'center',
            marginTop: 24
        }}>
            <TimePicker
                slotProps={{
                    toolbar: {hidden: false, className: 'tp1-toolbar'},
                    digitalClockItem: {className: 'tp1-clockItem'},
                    digitalClockSectionItem: {className: 'tp1-sectionItem'},
                    layout: {className: 'tp1-layout'},
                    desktopPaper: {style: {width: 220}}
                }}
                ampm={false}
                formatDensity={'spacious'}
                minutesStep={30}
                className={'tp1'}
                label="Start Time"
                value={startTime.toDate()}
                onChange={handleStartTimeChange}
                minTime={moment().hour(6).minute(0)}
                maxTime={moment().hour(24).minute(0)}
                renderInput={(params) => <TextField {...params} />}
            />
            <TimePicker
                slotProps={{
                    toolbar: {hidden: false, className: 'tp1-toolbar'},
                    digitalClockItem: {className: 'tp1-clockItem'},
                    digitalClockSectionItem: {className: 'tp1-sectionItem'},
                    layout: {className: 'tp1-layout'},
                    desktopPaper: {style: {width: 220}}
                }}
                ampm={false}
                formatDensity={'spacious'}
                minutesStep={30}
                className={'tp1'}
                label="End Time"
                value={endTime.toDate()}
                onChange={handleEndTimeChange}
                minTime={moment().hour(6).minute(0)}
                maxTime={moment().hour(24).minute(0)}
                renderInput={(params) => <TextField {...params} />}
            />
        </div>

        {!isRangeValid && (
            <p style={{color: 'red', marginTop: '10px'}}>
                The start time must be earlier than the end time.
            </p>
        )}
    </div>)
}

const WeeklyHours = forwardRef(({
                                    schedules,
                                    setTempEvents,
                                    tempEvents,
                                    setSchedules,
                                    fetchSchedules,
                                    setExpanded
                                }, ref) => {

    const intl = useIntl();
    const {user, fetchUser} = useAuth();
    const [isLoading, setIsLoading] = useState(false);
    const [hold, setHold] = useState(false);

    const [open, setOpen] = React.useState(false);
    const [openClone, setOpenClone] = React.useState(false);
    const [openCopyField, setOpenCopyField] = React.useState(false);
    const [modalContent, setModalContent] = React.useState(null);
    const [fieldToCopy, setFieldToCopy] = React.useState(null);
    const [daysOpened, setDaysOpened] = React.useState(new Set([]));
    const handleClose = () => {
        setOpen(false);
        setOpenCopyField(false);
        setOpenClone(false);
    };
    const handleCopyFieldExec = async () => {
        const newAvailability = cloneDeep(availability)
        for (const d of days) {
            for (const slot of newAvailability[d].slots) {
                if (destinationToCopy === 'all' || !slot[fieldToCopy]) {
                    slot[fieldToCopy] = slotToCopy[fieldToCopy];
                }
            }
        }
        setFieldToCopy(null);
        setModalContent(null);
        setAvailability(newAvailability);
        setOpenCopyField(false);
    }
    const handleCopyExec = async () => {
        const newAvailability = cloneDeep(availability)
        for (const d of daysToCopy) {
            newAvailability[d].slots.push({
                ...cloneDeep(availabilityTemplate(d)),
                startTime: slotToCopy.startTime,
                endTime: slotToCopy.endTime,
                activity: slotToCopy.activity,
                comment: slotToCopy.comment,
                location: slotToCopy.location,
                maxUsers: slotToCopy.maxUsers,
                price: slotToCopy.price,
                day: d,
                weekDay: d
            });
        }
        setAvailability(newAvailability);
        setOpen(false);
    };
    const [daysToCopy, setDaysToCopy] = useState([])
    const [slotToCopy, setSlotToCopy] = useState([])
    const [slotToClone, setSlotToClone] = useState([])
    const [cloneTimeRange, setCloneTimeRange] = useState(null)
    const [destinationToCopy, setDestinationToCopy] = useState(null);

    const handleCloneExec = useCallback(async () => {

        const newAvailability = cloneDeep(availability)
        const durationInMinutes = cloneTimeRange.endTime.diff(cloneTimeRange.startTime, 'minutes');
        const slotDurationInMinutes = moment(slotToClone.endTime, "HH:mm").diff(moment(slotToClone.startTime, "HH:mm"), 'minutes');
        let slotsToAdd = Math.floor(durationInMinutes / slotDurationInMinutes);

        if (moment(slotToClone.endTime, "HH:mm").isBetween(cloneTimeRange.startTime, cloneTimeRange.endTime) ||
            moment(slotToClone.startTime, "HH:mm").isBetween(cloneTimeRange.startTime, cloneTimeRange.endTime)) {
            slotsToAdd--;
        }
        const startPoint = cloneDeep(cloneTimeRange.startTime);
        for (let i = 0; i <= slotsToAdd; i++) {
            if (slotToClone.startTime !== moment(startPoint).add(slotDurationInMinutes * i, 'minutes').format('HH:mm')) {
                newAvailability[slotToClone.weekDay].slots.push({
                    ...cloneDeep(availabilityTemplate(slotToClone.weekDay)),
                    startTime: moment(startPoint).add(slotDurationInMinutes * i, 'minutes').format('HH:mm'),
                    endTime: moment(startPoint).add(slotDurationInMinutes * (i + 1), 'minutes').format('HH:mm'),
                    activity: slotToClone.activity,
                    comment: slotToClone.comment,
                    location: slotToClone.location,
                    maxUsers: slotToClone.maxUsers,
                    price: slotToClone.price,
                    day: slotToClone.weekDay,
                    weekDay: slotToClone.weekDay
                })
            }
        }

        setAvailability(newAvailability);
        setOpenClone(false);
    }, [cloneTimeRange, slotToClone]);

    const CopyComponent = ({slot, setDaysToCopyFunc}) => {
        setSlotToCopy(slot)
        return (<div>
            <h4>Select days to copy this slot to</h4>
            <List sx={{width: '100%', maxWidth: 360, bgcolor: 'background.paper'}}>
                {Object.values(initialAvailability).filter(d => d.day !== slot.weekDay).map(d => (
                        <ListItem
                            key={d.weekDay}
                            disablePadding
                        >
                            <ListItemButton dense>
                                <ListItemIcon>
                                    <Checkbox
                                        id={d.day}
                                        edge="start"

                                        onChange={(e) => {
                                            if (e.target.checked) {
                                                setDaysToCopy(d => [...d, e.target.id])
                                            }
                                        }}
                                    />
                                </ListItemIcon>
                                <ListItemText primary={(
                                    <strong>{moment().set({day: d.weekDay}).toDate().toLocaleString("en", {weekday: "long"})}</strong>)}/>
                            </ListItemButton>
                        </ListItem>
                    )
                )}
            </List>
        </div>)
    }
    const CopyFieldComponent = ({slot, fieldName}) => {
        setSlotToCopy(slot)
        setFieldToCopy(fieldName)
        setDestinationToCopy('empty')
        return (<div>
            <h4>Select destination of this "{fieldName}" field</h4>
            <hr/>
            <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue="empty"
                name="radio-buttons-group"
            >
                <FormControlLabel value="all"
                                  onChange={(e) => {
                                      if (e.target.checked) {
                                          setDestinationToCopy('all')
                                      }
                                  }}
                                  control={<Radio/>} label="All fields"/>
                <FormControlLabel value="empty"
                                  onChange={(e) => {
                                      if (e.target.checked) {
                                          setDestinationToCopy('empty')
                                      }
                                  }}
                                  control={<Radio/>} label="Only empty fields"/>
            </RadioGroup>


        </div>)
    }

    const createOption = (label, val = null) => ({
        label,
        value: val || v4(),
    });
    const options = useMemo(() => ({
        activities: user?.dicts?.sports?.filter(s => user?.coachData.activities.findIndex(a => a.id === s.key) !== -1).map(a => ({
            label: a.name[intl.locale],
            value: a.key
        })) || [],
        employees: user?.coachData?.employees?.map(a => ({
            label: a.name,
            value: a.id
        })) || [],
        locations: user?.coachData?.locations?.map(a => ({
            label: a.name,
            value: a.id
        })) || [],
        maxUsers: range(1, 31).map(a => ({
            label: a,
            value: a
        })) || [],
    }), [intl.locale]);

    const [availability, setAvailability] = useState(cloneDeep(initialAvailability));

    const handleSwitchChange = (day) => {
        const newAvailability = cloneDeep(availability);
        newAvailability[day].enabled = !Boolean(newAvailability[day].enabled);
        setAvailability(newAvailability);
    };
    const handleFieldChange = (field, value, day, index) => {
        const newAvailability = cloneDeep(availability);
        newAvailability[day].slots[index][field] = value;
        setAvailability(newAvailability);
    };

    useEffect(() => {
        if (schedules) {
            const newAvailability = cloneDeep(availability);
            for (const day of days) {
                newAvailability[day].slots = schedules.filter(s => s.weekDay === day).map(s => {
                    //(s, s.timeStart)
                    return {
                        ...s,
                        start: undefined,
                        end: undefined,
                        id: s.uuid,
                        startTime: s.startTime,
                        endTime: s.endTime
                    }
                })
            }
            setAvailability(newAvailability);
        }
    }, [schedules, intl.locale])

    const convertTempEvents = useCallback(() => {
        const eventsList = [];
        for (const day of Object.keys(availability)) {
            const dayData = cloneDeep(availability[day]);
            if (dayData.enabled === true) {
                for (const eventData of dayData.slots) {
                    eventsList.push({
                        ...eventData,
                        id: eventData.uuid,
                        startTime: eventData.startTime,
                        endTime: eventData.endTime,
                        groupId: eventData.uuid,
                        daysOfWeek: [initialAvailability[day].weekDay],
                        startDate: undefined,
                        title: `Preview`,
                        start: undefined,
                        end: undefined,
                        day,
                        editable: true,
                        display: 'auto',
                        status: 'pending',
                        className: `event-danger-dashed-new text-black`,
                        isPreview: true,
                    })
                }
            }
        }
        setTempEvents(eventsList)
    }, [availability, intl.locale])

    useEffect(() => {
        if (!hold) {
            try {
                convertTempEvents();
            } catch (e) {
                console.log(e)
            }
        }
        return () => {
        }
    }, [availability, hold])

    ref.setDaysOpened = useCallback((v) => setDaysOpened(new Set([...v])), [setDaysOpened])

    ref.updateSlot = useCallback((slotData) => {
        const slot = cloneDeep(slotData)
        const newAvailability = cloneDeep(availability);
        const slotToEdit = cloneDeep(newAvailability[slot.day].slots.findIndex(s => s.uuid === slot.uuid));

        newAvailability[slot.day].slots[slotToEdit] = {
            ...newAvailability[slot.day].slots[slotToEdit],
            ...slot,
            timeStart: slot.startTime,
            endTime: slot.endTime,
            groupId: slot.uuid,
            isGroup: 1,
            id: slot.uuid,
            end: undefined,
            start: undefined,
        }
        setAvailability(newAvailability);
        const scheduleIndex = schedules.findIndex(s => s.uuid === slot.uuid);
        if (scheduleIndex !== -1) {
            const newSchedules = cloneDeep(schedules);
            newSchedules[scheduleIndex].endTime = slot.endTime;
            newSchedules[scheduleIndex].startTime = slot.startTime;
            setSchedules(newSchedules);
        }
    }, [availability, schedules]);

    const handleAddSlot = (day) => {
        const newAvailability = cloneDeep(availability)
        const slot = availabilityTemplate(day);
        newAvailability[day].slots.push(slot);
        setAvailability(newAvailability);
    };

    const handleDeleteSlot = (day, index) => {
        const newAvailability = cloneDeep(availability);
        newAvailability[day].slots.splice(index, 1);
        setAvailability(newAvailability);
    };
    const handleCopySlot = useCallback((day, index) => {
        const newAvailability = cloneDeep(availability);
        const slot = newAvailability[day].slots[index]
        setDaysToCopy([]);
        setModalContent(<CopyComponent slot={slot} daysToCopyArray={daysToCopy} setDaysToCopyFunc={setDaysToCopy}/>)
        setOpen(true)
    }, [daysToCopy, availability]);

    const handleCloneSlot = useCallback((day, index) => {
        const newAvailability = cloneDeep(availability);
        const slot = newAvailability[day].slots[index];
        setModalContent(<CloneComponent slot={slot} setCloneTimeRange={setCloneTimeRange}/>)
        setOpenClone(true)
        setSlotToClone(slot)
    }, [daysToCopy, availability]);

    const handleCopySlotField = useCallback((day, fieldName, index) => {
        const newAvailability = cloneDeep(availability);
        const slot = newAvailability[day].slots[index]
        setModalContent(<CopyFieldComponent fieldName={fieldName} slot={slot} daysToCopyArray={daysToCopy}
                                            setDaysToCopyFunc={setDaysToCopy}/>)
        setOpenCopyField(true)
    }, [daysToCopy, availability]);


    const handleCreate = async (id, key, inputValue) => {
        setIsLoading(true);
        const newOption = createOption(inputValue);
        await api.post('users/add-option', {key, item: {id: newOption.value, name: newOption.label}})
        setIsLoading(false);
        await fetchUser()
    };

    const handleSave = async (newAvailability) => {
        setIsLoading(true);
        await api.post('schedules', newAvailability)
        setIsLoading(false);
        fetchSchedules();
        setExpanded(false)
    }

    ref.handleSave = useCallback(async (newSchedules) => {
        const newAvailability = cloneDeep(availability);
        for (const schedule of newSchedules) {
            const slotToEdit = newAvailability[schedule.weekDay].slots.findIndex(s => s.uuid === schedule.uuid)
            newAvailability[schedule.weekDay].slots[slotToEdit].endTime = schedule.endTime
            newAvailability[schedule.weekDay].slots[slotToEdit].startTime = schedule.startTime
        }
        setAvailability(newAvailability);
        await handleSave(newAvailability)
    }, [availability, schedules]);


    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <MDDialog
                title={intl.formatMessage({id: "weekly.copy.field", defaultMessage: 'Copy Field Value'})}
                open={openCopyField}
                saveButtonText={'Copy'}
                onClose={handleClose}
                onSave={handleCopyFieldExec}
                content={modalContent}
            />
            <MDDialog
                title={intl.formatMessage({id: "weekly.copy", defaultMessage: 'Copy Slot'})}
                open={open}
                saveButtonText={'Copy'}
                onClose={handleClose}
                onSave={handleCopyExec}
                content={modalContent}
            />
            <MDDialog
                title={intl.formatMessage({id: "weekly.clone", defaultMessage: 'Clone Slot'})}
                open={openClone}
                saveButtonText={'Clone'}
                onClose={handleClose}
                onSave={handleCloneExec}
                content={modalContent}
            />
            <fieldset className={`form-fieldset weekly-editor ${daysOpened.size > 0 ? '' : 'all-closed'}`}>
                <legend className={'weekly-editor-legend'}><FormattedMessage id={"schedule.edit"}
                                                                             defaultMessage={'Your weekly schedule'}/>
                </legend>

                {days.map((day) => (
                    <Row key={day} id={`row-day-${day}`} className={'weekly-editor-row'}>
                        {day === 'MON' && daysOpened.size > 0 && (
                            <div className={'weekly-save'} style={{position: 'absolute', right: 16, top: 77}}>
                                <MDButton
                                    variant="contained"
                                    color="success"
                                    size={'large'}
                                    startIcon={<Save/>}
                                    style={{marginTop: 4, width: 200}}
                                    onClick={() => handleSave(availability)}
                                >
                                    Save
                                </MDButton>
                            </div>
                        )}
                        <Col style={{maxWidth: 120, width: 120}}>
                            <MDBox
                                display="flex"
                                justifyContent="flex-start"
                                alignItems="center"
                                mb={0}
                                lineHeight={1}
                            >
                                <CheckBox
                                    sx={{'& .MuiSvgIcon-root': {width: '2rem', height: '2rem'}}}
                                    color={'secondary'}
                                    checked={availability[day].enabled}
                                    onChange={() => handleSwitchChange(day)}
                                />
                                <Typography>{day}</Typography>
                            </MDBox>
                        </Col>
                        <Col style={{marginTop: 4}}>

                            {daysOpened.has(day) ? (<Table className="table-weekly">
                                    <tbody>
                                    {availability[day].enabled && availability[day].slots.map((slot, index) => (
                                        <tr key={`slot_${slot.uuid}`} id={`slot_${slot.uuid}`}>
                                            <td className={'stack-td'}>
                                                <Stack
                                                    key={index}
                                                    direction="row"
                                                    spacing={1}
                                                    alignItems="center"

                                                >
                                                    <TimePicker
                                                        slotProps={{
                                                            toolbar: {hidden: false, className: 'tp1-toolbar'},
                                                            digitalClockItem: {className: 'tp1-clockItem'},
                                                            digitalClockSectionItem: {className: 'tp1-sectionItem'},
                                                            layout: {className: 'tp1-layout'},
                                                            desktopPaper: {style: {width: 220}}
                                                        }}
                                                        ampm={false}
                                                        formatDensity={'spacious'}
                                                        minutesStep={5}
                                                        className={'tp1'}
                                                        label="Start"
                                                        value={moment(slot.startTime, "HH:mm").toDate()}
                                                        onChange={(value) => {
                                                            handleFieldChange('startTime', moment(value).format('HH:mm'), day, index);
                                                        }}
                                                    />
                                                    <Box sx={{mx: 0}}> to </Box>
                                                    <TimePicker
                                                        slotProps={{
                                                            toolbar: {hidden: false, className: 'tp1-toolbar'},
                                                            digitalClockItem: {className: 'tp1-clockItem'},
                                                            digitalClockSectionItem: {className: 'tp1-sectionItem'},
                                                            layout: {className: 'tp1-layout'},
                                                            desktopPaper: {style: {width: 220}}
                                                        }}
                                                        className={'tp1'}
                                                        ampm={false} formatDensity={'spacious'} minutesStep={5}
                                                        label="End"
                                                        value={moment(slot.endTime, "HH:mm").toDate()}
                                                        onChange={(value) => {
                                                            handleFieldChange('endTime', moment(value).format('HH:mm'), day, index)
                                                        }}
                                                    />

                                                    <div className={'weekly-delete-button d-none weekly-editor-actions'}>
                                                        <div>
                                                            <Button
                                                                variant="contained"
                                                                id={'clone'}
                                                                title={'clone inside the day'}
                                                                onClick={() => handleCloneSlot(day, index)}
                                                            >
                                                                <PlaylistAdd className={'weekly-action'} color={'white'}
                                                                             fontSize={'large'}/>
                                                            </Button>
                                                            <Button
                                                                variant="contained"
                                                                id={'copy'}
                                                                title={'copy to other days'}
                                                                onClick={() => handleCopySlot(day, index)}
                                                            >
                                                                <CopyAll className={'weekly-action'} color={'white'}
                                                                         fontSize={'large'}/>
                                                            </Button>
                                                            <Button
                                                                variant="contained"
                                                                id={'delete'}
                                                                title={'delete slot'}
                                                                onClick={() => handleDeleteSlot(day, index)}
                                                            >
                                                                <Delete className={'weekly-action'} color={'white'}
                                                                        fontSize={'large'}/>
                                                            </Button>
                                                        </div>
                                                    </div>
                                                </Stack>
                                            </td>
                                            <td className={'table-weekly-td-responsive'}>
                                                <div>
                                                    <FormField variant={'outlined'}
                                                               label={intl.formatMessage({id: "common.sport"})} component={
                                                        <Select
                                                            noOptionsMessage={() => 'Type new item and press Enter to create'}

                                                            placeholder={''}
                                                            isDisabled={isLoading}
                                                            isLoading={isLoading}
                                                            onChange={(v) => handleFieldChange('activity', v, day, index)}
                                                            onCreateOption={(inputValue) => handleCreate('activity', 'activities', inputValue)}
                                                            options={options.activities}
                                                            value={options.activities.find(a => slot.activity === a.value)}
                                                            styles={{
                                                                valueContainer: (baseStyles) => ({
                                                                    ...baseStyles,
                                                                    padding: 2
                                                                }),
                                                                container: (baseStyles) => ({
                                                                    ...baseStyles,
                                                                    minHeight: 44,
                                                                    height: 44
                                                                }),
                                                                control: (baseStyles) => ({
                                                                    ...baseStyles,
                                                                    minHeight: 44,
                                                                    height: 44,
                                                                    padding: 0
                                                                }),
                                                                menu: (baseStyles) => ({
                                                                    ...baseStyles,
                                                                    zIndex: 2
                                                                }),
                                                            }}
                                                        />}/>
                                                </div>
                                                <div>
                                                    <div className={'table-weekly-td-content'}>
                                                        <FormField variant={'outlined'}
                                                                   label={intl.formatMessage({id: "schedule.price"})}
                                                                   placeholder={intl.formatMessage({id: "schedule.price"})}
                                                                   value={slot.price}
                                                                   inputProps={{
                                                                       variant: 'outlined',
                                                                       type: 'number',
                                                                       min: 0,
                                                                       minimum: 0,
                                                                       value: slot.price,
                                                                       onChange: e => {
                                                                           let price = parseInt(e.target.value);
                                                                           if (price < 0) {
                                                                               price = 0;
                                                                           }
                                                                           handleFieldChange('price', price, day, index)
                                                                       }
                                                                   }}/>
                                                        <Button
                                                            className={'weekly-action-inline'}
                                                            id={`copy-activity-${slot.uuid}`}
                                                            title={'copy to other days'}
                                                            onClick={() => handleCopySlotField(day, 'price', index)}
                                                        >
                                                            <ContentCopy color={'info'}/>
                                                        </Button>
                                                    </div>
                                                </div>
                                                <div>
                                                    <div className={'table-weekly-td-content'}>
                                                        <div style={{position: 'relative'}}>
                                                            <CommentTextField
                                                                value={slot.comment}
                                                                index={index}
                                                                day={day}
                                                                handleFieldChange={handleFieldChange}
                                                            />
                                                        </div>
                                                        <Button
                                                            className={'weekly-action-inline'}
                                                            id={`copy-activity-${slot.uuid}`}
                                                            title={'copy to other days'}
                                                            onClick={() => handleCopySlotField(day, 'comment', index)}
                                                        >
                                                            <ContentCopy color={'info'}/>
                                                        </Button>
                                                    </div>
                                                </div>
                                                <div>
                                                    <div className={'table-weekly-td-content'}>
                                                        <FormField variant={'outlined'}
                                                                   label={intl.formatMessage({id: "schedule.location"})}
                                                                   component={<Select
                                                                       placeholder={''}
                                                                       noOptionsMessage={() => 'Type new item and press Enter to create'}
                                                                       isDisabled={isLoading}
                                                                       isLoading={isLoading}
                                                                       onChange={(newValue) => {
                                                                           if (newValue) {
                                                                               handleFieldChange('location', newValue.value, day, index)
                                                                           } else {
                                                                               handleFieldChange('location', null, day, index)
                                                                           }
                                                                       }}
                                                                       onCreateOption={(inputValue) => handleCreate('location', 'locations', inputValue)}
                                                                       options={options.locations}
                                                                       value={options.locations.find(a => slot.location === a.value)}
                                                                       styles={{
                                                                           container: (baseStyles) => ({
                                                                               ...baseStyles,
                                                                               minHeight: 44,
                                                                               height: 44
                                                                           }),
                                                                           control: (baseStyles) => ({
                                                                               ...baseStyles,
                                                                               minHeight: 44,
                                                                               height: 44
                                                                           }),
                                                                       }}
                                                                   />}/>
                                                        <Button
                                                            className={'weekly-action-inline'}
                                                            id={`copy-activity-${slot.uuid}`}
                                                            title={'copy to other days'}
                                                            onClick={() => handleCopySlotField(day, 'location', index)}
                                                        >
                                                            <ContentCopy color={'info'}/>
                                                        </Button>
                                                    </div>
                                                </div>
                                            </td>
                                            <td className={'table-weekly-td pr-0'}>
                                                <div className={'table-weekly-td-content'}>
                                                    <FormField variant={'outlined'}
                                                               label={intl.formatMessage({id: "common.sport"})} component={
                                                        <Select
                                                            noOptionsMessage={() => 'Type new item and press Enter to create'}
                                                            placeholder={''}
                                                            isDisabled={isLoading}
                                                            isLoading={isLoading}
                                                            onChange={({value}) => handleFieldChange('activity', value, day, index)}
                                                            onCreateOption={(inputValue) => handleCreate('activity', 'activities', inputValue)}
                                                            options={options.activities}
                                                            value={options.activities.find(a => slot.activity === a.value)}
                                                            styles={{
                                                                placeholder: (baseStyles) => ({
                                                                    ...baseStyles,
                                                                    lineHeight: 1
                                                                }),
                                                                menu: (baseStyles) => ({...baseStyles, zIndex: 2}),
                                                                valueContainer: (baseStyles) => ({
                                                                    ...baseStyles,
                                                                    padding: 2
                                                                }),

                                                                container: (baseStyles) => ({
                                                                    ...baseStyles,
                                                                    minHeight: 44,
                                                                    height: 44
                                                                }),
                                                                control: (baseStyles) => ({
                                                                    ...baseStyles,
                                                                    minHeight: 44,
                                                                    height: 44,
                                                                }),
                                                            }}
                                                        />}/>
                                                    <Button
                                                        className={'weekly-action-inline'}
                                                        id={`copy-activity-${slot.uuid}`}
                                                        title={'copy to other days'}
                                                        onClick={() => handleCopySlotField(day, 'activity', index)}
                                                    >
                                                        <ContentCopy color={'info'}/>
                                                    </Button>
                                                </div>
                                            </td>
                                            <td className={'table-weekly-td pr-0'} style={{
                                                minWidth: 95,
                                                width: 95 + 16 * (slot.price || '').toString().length
                                            }}>
                                                <div className={'table-weekly-td-content keep-width'}>
                                                    <FormField variant={'outlined'}
                                                               label={intl.formatMessage({id: "schedule.price"})}
                                                               placeholder={intl.formatMessage({id: "schedule.price"})}
                                                               value={slot.price}
                                                               style={{
                                                                   minWidth: 32 + 16 * (slot.price || '').toString().length,
                                                                   width: (32 + 16 * (slot.price || '').toString().length).toString() + 'px !important'
                                                               }}
                                                               inputProps={{
                                                                   variant: 'outlined',
                                                                   type: 'number',
                                                                   value: slot.price,
                                                                   onChange: e => {
                                                                       let price = parseInt(e.target.value);
                                                                       if (price < 0) {
                                                                           price = 0;
                                                                       }
                                                                       handleFieldChange('price', price, day, index)
                                                                   }
                                                               }}/>
                                                    <Button
                                                        className={'weekly-action-inline'}
                                                        id={`copy-activity-${slot.uuid}`}
                                                        title={'copy to other days'}
                                                        onClick={() => handleCopySlotField(day, 'price', index)}
                                                    >
                                                        <ContentCopy color={'info'}/>
                                                    </Button>
                                                </div>
                                            </td>
                                            <td className={'table-weekly-td pr-0'}>
                                                <div className={'table-weekly-td-content'}>
                                                    <div style={{position: 'relative'}}>
                                                        <CommentTextField
                                                            value={slot.comment}
                                                            index={index}
                                                            day={day}
                                                            handleFieldChange={handleFieldChange}
                                                        />
                                                    </div>
                                                    <Button
                                                        className={'weekly-action-inline'}
                                                        id={`copy-activity-${slot.uuid}`}
                                                        title={'copy to other days'}
                                                        onClick={() => handleCopySlotField(day, 'comment', index)}
                                                    >
                                                        <ContentCopy color={'info'}/>
                                                    </Button>
                                                </div>

                                            </td>
                                            <td className={'table-weekly-td pr-2'}>
                                                <div className={'table-weekly-td-content'}>
                                                    <FormField variant={'outlined'}
                                                               label={intl.formatMessage({id: "schedule.location"})}
                                                               component={<Select
                                                                   placeholder={''}
                                                                   noOptionsMessage={() => 'Type new item and press Enter to create'}

                                                                   isDisabled={isLoading}
                                                                   isLoading={isLoading}
                                                                   onChange={(newValue) => {
                                                                       if (newValue) {
                                                                           handleFieldChange('location', newValue.value, day, index)
                                                                       } else {
                                                                           handleFieldChange('location', null, day, index)
                                                                       }
                                                                   }}
                                                                   onCreateOption={(inputValue) => handleCreate('location', 'locations', inputValue)}
                                                                   options={options.locations}
                                                                   value={options.locations.find(a => slot.location === a.value)}
                                                                   styles={{
                                                                       menu: (baseStyles) => ({...baseStyles, zIndex: 2}),
                                                                       container: (baseStyles) => ({
                                                                           ...baseStyles,
                                                                           minHeight: 44,
                                                                           height: 44
                                                                       }),
                                                                       control: (baseStyles) => ({
                                                                           ...baseStyles,
                                                                           minHeight: 44,
                                                                           height: 44
                                                                       }),
                                                                   }}
                                                               />}/>
                                                    <Button
                                                        className={'weekly-action-inline'}
                                                        id={`copy-activity-${slot.uuid}`}
                                                        title={'copy to other days'}
                                                        onClick={() => handleCopySlotField(day, 'location', index)}
                                                    >
                                                        <ContentCopy color={'info'}/>
                                                    </Button>
                                                </div>
                                            </td>
                                            <td style={{width: 100, maxWidth: 100, paddingLeft: 4}}>
                                                <FormField variant={'outlined'}
                                                           label={intl.formatMessage({id: "common.attendees.of"})}
                                                           component={
                                                               <Select options={options.maxUsers}
                                                                       value={createOption(slot.maxUsers)}
                                                                       onChange={({value}) => {
                                                                           handleFieldChange('maxUsers', value, day, index)
                                                                       }}
                                                                       styles={{
                                                                           menu: (baseStyles) => ({
                                                                               ...baseStyles,
                                                                               zIndex: 2
                                                                           }),
                                                                           container: (baseStyles) => ({
                                                                               ...baseStyles,
                                                                               minHeight: 44,
                                                                               height: 44,

                                                                               minWidth: 64 + 16 * (slot.maxUsers || '').toString().length,
                                                                               width: (64 + 16 * (slot.maxUsers || '').toString().length).toString() + 'px !important'

                                                                           }),
                                                                           control: (baseStyles) => ({
                                                                               ...baseStyles,
                                                                               minHeight: 44,
                                                                               height: 44
                                                                           }),
                                                                       }}
                                                               />}/>
                                            </td>
                                            <td className={'weekly-editor-actions'}>
                                                <div>
                                                    <Button
                                                        variant="contained"
                                                        id={'clone'}
                                                        title={'clone inside the day'}
                                                        onClick={() => handleCloneSlot(day, index)}
                                                    >
                                                        <PlaylistAdd className={'weekly-action'} color={'white'}
                                                                     fontSize={'large'}/>
                                                    </Button>
                                                    <Button
                                                        variant="contained"
                                                        id={'copy'}
                                                        title={'copy to other days'}
                                                        onClick={() => handleCopySlot(day, index)}
                                                    >
                                                        <CopyAll className={'weekly-action'} color={'white'}
                                                                 fontSize={'large'}/>
                                                    </Button>
                                                    <Button
                                                        variant="contained"
                                                        id={'delete'}
                                                        title={'delete slot'}
                                                        onClick={() => handleDeleteSlot(day, index)}
                                                    >
                                                        <Delete className={'weekly-action'} color={'white'}
                                                                fontSize={'large'}/>
                                                    </Button>
                                                </div>
                                            </td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </Table>) :
                                (<div
                                    className={'mt-n2 p-1 collapsed-weekly-row'}>
                                    <MDButton
                                        variant="contained"
                                        color="violet"
                                        size={'large'}
                                        startIcon={<ViewList/>}
                                        style={{marginTop: 4, width: 200}}
                                        onClick={() => setDaysOpened(d => new Set([...d, day]))}
                                    >
                                        Show {availability[day].slots.length} slot{availability[day].slots.length > 1 ? 's' : ''}
                                    </MDButton>
                                    {availability[day].enabled && availability[day].slots.map(slot => (
                                        <MDBadge key={slot.uuid} color={'info'} badgeContent={slot.startTime}/>))}
                                </div>)
                            }
                            {daysOpened.has(day) && availability[day].enabled && (<div className={'weekly-row-buttons'}>
                                    <MDButton
                                        variant="contained"
                                        color="info"
                                        startIcon={<Add/>}
                                        style={{marginTop: 6, marginBottom: 8}}
                                        onClick={() => handleAddSlot(day)}
                                    >
                                        Add Slot
                                    </MDButton>
                                    <MDButton
                                        variant="contained"
                                        color="secondary"
                                        startIcon={<UnfoldLess/>}
                                        style={{marginLeft: 24}}
                                        onClick={() => {
                                            const tempSet = new Set(daysOpened);
                                            tempSet.delete(day);
                                            setDaysOpened(tempSet);
                                        }}
                                    >
                                        Hide slots
                                    </MDButton>
                                </div>
                            )}

                        </Col>
                        {day === 'SUN' && daysOpened.size > 0 && (
                            <div style={{position: 'absolute', right: 20, bottom: 10}}>
                                <MDButton
                                    variant="contained"
                                    color="success"
                                    size={'large'}
                                    startIcon={<Save/>}
                                    style={{marginTop: 4, width: 200}}
                                    onClick={() => handleSave(availability)}
                                >
                                    Save
                                </MDButton>
                            </div>
                        )}
                    </Row>
                ))}
            </fieldset>
        </LocalizationProvider>
    );
});

export {
    WeeklyHours
};
