import {useEffect, useState} from "react";
import MDBox from "components/MDBox";
import ImgsViewer from "lib/react-images-viewer/src/ImgsViewer";
import Stack from "@mui/material/Stack";

export const MediaViewer = ({items}) => {
    const [currentImage, setCurrentImage] = useState(items[0]);
    const [imgsViewer, setImgsViewer] = useState(false);
    const [imgsViewerCurrent, setImgsViewerCurrent] = useState(0);

    const handleSetCurrentImage = ({currentTarget}) => {
        setCurrentImage(items[Number(currentTarget.id)]);
        setImgsViewerCurrent(Number(currentTarget.id));
    };

    useEffect(() => {
        if (!currentImage && items[0]) setCurrentImage(items[0])
    }, [items])

    const openImgsViewer = () => setImgsViewer(true);
    const closeImgsViewer = () => setImgsViewer(false);
    const imgsViewerNext = () => setImgsViewerCurrent(imgsViewerCurrent + 1);
    const imgsViewerPrev = () => setImgsViewerCurrent(imgsViewerCurrent - 1);

    return (
        <MDBox>
            <ImgsViewer
                imgs={items.map(item => ({src: item.url, srcSet: [item.url], caption: item.caption || ''}))}
                isOpen={imgsViewer}
                onClose={closeImgsViewer}
                currImg={imgsViewerCurrent}
                onClickPrev={imgsViewerPrev}
                onClickNext={imgsViewerNext}
                backdropCloseable
            />

            {currentImage && <MDBox
                component="img"
                src={currentImage.url}
                alt="Product Image"
                shadow="lg"
                borderRadius="lg"
                width="100%"
                style={{maxHeight: 600}}
                sx={{cursor: "pointer", objectFit: "contain", backgroundSize: 'auto 100%'}}
                onClick={openImgsViewer}
            />}
            <MDBox mt={2} pt={1} style={{maxWidth: '100%', overflowX: 'auto'}}>
                <Stack direction="row" spacing={3}>
                    {items.map((item, i) => (<MDBox
                            component="img"
                            key={i}
                            id={i}
                            src={item.url}
                            alt={item.caption || item.url}
                            borderRadius="lg"
                            shadow="md"
                            width="100%"
                            height="5rem"
                            minHeight="5rem"
                            maxWidth={'7.5rem'}
                            minWidth={'5rem'}
                            sx={{cursor: "pointer", objectFit: "contain", backgroundSize: 'auto 100%'}}
                            onClick={handleSetCurrentImage}
                        />
                    ))}
                </Stack>
            </MDBox>
        </MDBox>
    )
}
