import React, {useRef} from 'react'
import MDBadge from "components/MDBadge";
import StarIcon from '@mui/icons-material/Star';
import HalfStarIcon from '@mui/icons-material/StarHalf';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import MDTypography from "components/MDTypography";
import {useIntl} from "react-intl";

const Marker = ({
                    className,
                    lat,
                    lng,
                    markerId,
                    onClick,
                    key,
                    draggable,
                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                    onDrag,
                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                    onDragEnd,
                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                    onDragStart,
                    score,
                    children,
                    ...props
                }) => {
    const intl = useIntl();
    const markerRef = useRef();
    const StarIconComponent = score === 0 ? HalfStarIcon : (score < 2 ? StarBorderIcon : (score > 4 ? StarIcon : HalfStarIcon))
    return lat && lng ? (
        <><MDBadge className={'arrow'} color={'info'} badgeContent={<div>
            <MDTypography variant="button" color={'gold'} fontWeight="medium">
                <StarIconComponent style={{marginTop: -4}} fontSize={'small'}
                                   color={'gold'}/> {score === 0 ? '' : parseFloat(score).toFixed(1)}</MDTypography>
        </div>} container
                   onClick={(e) => {
                       e.preventDefault()
                       onClick(markerId, e)
                   }}
        />
            <div ref={markerRef} id={`tooltip${markerId}`}>&nbsp;</div>
            {markerRef.current && children}
        </>
    ) : <strong>AAA</strong>
}

export default Marker
