/*!

=========================================================
* Paper Kit React-v1.3.2
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2023 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";

// core components
import ExamplesNavbar from "components/Navbars/ExamplesNavbar.js";
import LandingPageHeader from "components/Headers/LandingPageHeader.js";
import DemoFooter from "components/Footers/DemoFooter.js";
import {useIntl} from "react-intl";

import hero from "../../assets/img/hero.png";
import about1 from "../../assets/img/about1.png";
import about2 from "../../assets/img/about2.png";
import about3 from "../../assets/img/about3.png";
import about4 from "../../assets/img/about4.png";
//import about5 from "../../assets/img/about5.png";
import about7 from "../../assets/img/about7.png";




import './About.css'

function LandingPage() {
  const {locale} = useIntl()
  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("profile-page");
    return function cleanup() {
      document.body.classList.remove("profile-page");
    };
  });
  return (
      <>
        <ExamplesNavbar/>

        <div className={'about-us-page'} style={{width: '100%', backgroundColor: 'white', marginTop: 90}}>
        <div style={{margin: '0 auto', borderRadius: 24, width: '100%'}}>
          <div className="about-us-v9lB7P" data-id="10:5">

            <div className="hero-hlgf4F" data-id="16:36" style={{
                width: '100%',
                borderRadius: 24,
                backgroundImage: `url(${hero})`,
                backgroundSize:'cover',
                backgroundPosition: 'center center',
              backgroundRepeat: 'no-repeat'
            }}>

                <div className="titletext-ViSrhg" data-id="13:12" >
                  <p className="about-spotya-HlSvDX hero-header" data-id="11:294">About SPOTYA</p></div>

            </div>
            <div className="content-hlgf4F" data-id="16:35">
              <div className="numbers-rC60Ku" data-id="11:313">
                <div className="number-57eJYP" data-id="11:314">
                  <p className="x88-UA3lca" data-id="11:315">88+</p><p className="type-of-sports-on-offer-UA3lca"
                                                                       data-id="11:316">Type of sports on offer</p>
                </div>
                <div className="number-elH6ha" data-id="11:320">
                  <p className="x165-OX69xr" data-id="11:321">165</p><p className="avaliable-coaches-OX69xr"
                                                                        data-id="11:322">Avaliable coaches</p></div>
                <div className="number-eNvUM6" data-id="11:317">
                  <p className="x4k-IC3bfC" data-id="11:318">4K+</p><p className="found-their-perfect-coach-IC3bfC"
                                                                       data-id="11:319">Found their perfect coach</p>
                </div>
                <div className="number-yB1k1W" data-id="11:323">
                  <p className="x97-nrum84" data-id="11:324">97%</p><p className="positive-reviews-nrum84"
                                                                       data-id="11:325">Positive reviews</p></div>
              </div>
              <div className="about-us-rC60Ku" data-id="11:302">
                <p className="to-make-finding-sports-coaches-easy-for-everyone-ZQMZg4" data-id="11:303">To make finding
                  sports coaches easy for everyone</p><p
                  className="we’e-noticed-that-many-people-have-a-hard-time-finding-the-right-coach-to-start-with-at-spotya-we-help-by-quickly-connecting-clients-with-trustworthy-coaches-discover-coach-profiles-reviews-schedules-and-book-appointments-smoothly-it-will-help-you-to-feel-confident-in-decision-making-and-save-coaches-time-on-proving-themselves-ZQMZg4"
                  data-id="11:304">We’e noticed that many people have a hard time finding the right coach to start with.
                At Spotya, we help by quickly connecting clients with trustworthy coaches. Discover coach profiles,
                reviews, schedules, and book appointments smoothly. It will help you to feel confident in decision
                making and save coaches time on proving themselves.</p></div>
              <div className="mission-rC60Ku" data-id="13:47">
                <div className="content-jFwm02" data-id="13:46">
                  <div className="titlesubtitle-Mk6lqA" data-id="16:38">
                    <p className="our-mission-tH9iVw" data-id="13:4">Our mission</p><p
                      className="simplify-your-sports-coaching-journey-with-spotya-tH9iVw" data-id="13:6">Simplify Your
                    Sports Coaching Journey with Spotya</p></div>
                  <p className="spotya-aims-to-make-the-process-of-finding-and-booking-a-sports-coach-simple-and-efficient-for-everyone-we-believe-that-everyone-deserves-access-to-quality-coaching-and-we-provide-the-tools-to-make-this-happen-Mk6lqA"
                     data-id="16:37">Spotya aims to make the process of finding and booking a sports coach simple and
                    efficient for everyone. We believe that everyone deserves access to quality coaching, and we provide
                    the tools to make this happen.</p></div>
                <img
                    src={about1}
                    className="photo-jFwm02 decoded loaded px-4" data-id="13:45" style={{height: '680px'}}/>
              </div>
              <div className="why-us-rC60Ku" data-id="16:32">
                <div className="titlesubtitle-Y0rnOi" data-id="16:34">
                  <p className="why-us-n32K3b" data-id="13:50">Why Us</p><p className="our-commitment-to-you-n32K3b"
                                                                            data-id="16:33">Our Commitment to you</p>
                </div>
                <div className="content-Y0rnOi" data-id="16:31">
                  <div className="block-XL33DW" data-id="16:15">
                    <img
                        src={about2}
                        className="mockuuups-holding-samsung-s20-mockup-in-front-of-a-bike-p7dkh8 decoded loaded"
                        data-id="16:12"/>
                    <div className="frame-4-p7dkh8" data-id="16:14">
                      <p className="discover-top-sports-instructors-near-you-PRYdRi" data-id="14:7" style={{padding: 0}}>Discover Top Sports
                        Instructors Near You</p><p
                        className="to-help-explore-the-closest-and-finest-options-near-you-by-starting-to-train-today-by-using-our-filtering-page-additional-features-will-allow-you-to-find-exactly-what-you’re-looking-for-PRYdRi"
                        data-id="13:3">To help explore the closest and finest options near you by starting to train
                      today by using our filtering page. Additional features will allow you to find exactly what you’re
                      looking for.</p></div>
                  </div>
                  <div className="block-9yBehs" data-id="16:16">
                    <div className="frame-4-vhpn4e" data-id="16:18">
                      <p className="discover-top-sports-instructors-near-you-PRYdRi" data-id="14:7" style={{padding: 0}}>Comprehensive
                        Instructor
                        Profiles</p><p
                        className="allow-to-check-out-all-the-information-social-media-prices-locations-certificates-reviews-and-more-to-pick-the-best-sports-instructor-for-yourself-4FPXuH"
                        data-id="16:20">Allow to check out all the information, social media, prices, locations,
                      certificates, reviews, and more to pick the best sports instructor for yourself.</p></div>
                    <img
                        src={about3}
                        className="mockuuups-holding-samsung-s20-mockup-in-front-of-a-bike-vhpn4e decoded loaded"
                        data-id="16:17"/>
                  </div>
                  <div className="block-KGUG6Z" data-id="16:21">
                    <img
                        src={about4}
                        className="mockuuups-holding-samsung-s20-mockup-in-front-of-a-bike-86fW4y decoded loaded"
                        data-id="16:22"/>
                    <div className="frame-4-86fW4y" data-id="16:23">
                      <p className="convenient-booking-process-7QEvON" data-id="16:24">Convenient Booking Process</p><p
                        className="provide-best-way-to-book-a-session-with-a-coach-you-like-without-even-calling-them-first-check-when-they-are-free-and-then-send-a-request-to-book-for-a-session-7QEvON"
                        data-id="16:25">Provide best way to book a session with a coach you like without even calling
                      them first! Check when they are free, and then send a request to book for a session.</p></div>
                  </div>
                  <div className="block-woNqdT" data-id="16:26">
                    <div className="frame-4-eBb9LK" data-id="16:28">
                      <p className="track-your-bookings-16cIvy" data-id="16:29">Track Your Bookings</p><p
                        className="and-finally---easily-sign-up-and-log-in-to-track-all-the-sports-sessions-youve-booked-in-one-place-over-time-16cIvy"
                        data-id="16:30">And finally-easily sign up and log in to track all the sports sessions you've
                      booked in one place over time.</p></div>
                    <img
                        src={about7}
                        className="mockuuups-holding-samsung-s20-mockup-in-front-of-a-bike-eBb9LK decoded loaded"
                        data-id="16:27"/>
                  </div>
                </div>
              </div>
            </div>
            <div className="footer--hlgf4F" data-id="16:230">
              <div className="content-wYjOk4" data-id="I16:230;16:138">
                <div className="xіі--L1miAN" data-id="I16:230;16:139">
                  <p className="logo-Jc20Wv" data-id="I16:230;16:140">Logo</p><p
                    className="lorem-ipsum is-simply-dummy-text-of-the-printing-and-typesetting-industry-Jc20Wv"
                    data-id="I16:230;16:141">Lorem Ipsum&nbsp;is simply dummy text of the printing and typesetting
                  industry.</p></div>
                <div className="column-L1miAN" data-id="I16:230;16:152">
                  <p className="navigation-UO7YH9" data-id="I16:230;16:153">Navigation</p>
                  <div className="footer-links-UO7YH9" data-id="I16:230;16:154">
                    <div className="link-VTWYeP" data-id="I16:230;16:155">
                      <p className="home-dUfDrQ" data-id="I16:230;16:156">Home</p></div>
                    <div className="link-wVewbE" data-id="I16:230;16:159">
                      <p className="about-us-KjCUrW" data-id="I16:230;16:160">About Us</p></div>
                    <div className="link-FBWrZU" data-id="I16:230;16:161">
                      <p className="coaches-dWv3BH" data-id="I16:230;16:162">Coaches</p></div>
                    <div className="link-QDf1qA" data-id="I16:230;16:163">
                      <p className="my-account-8WR7wx" data-id="I16:230;16:164">My Account</p></div>
                  </div>
                </div>
                <div className="column-HCP5eq" data-id="I16:230;16:275">
                  <p className="social-media-2vqZ2n" data-id="I16:230;16:276">Social media</p>
                  <div className="footer-links-2vqZ2n" data-id="I16:230;16:277">
                    <div className="link-vAKeAB" data-id="I16:230;16:278">
                      <img
                          src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjUiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNSAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTEyLjY2NjcgMTZDMTQuODc1OCAxNiAxNi42NjY3IDE0LjIwOTEgMTYuNjY2NyAxMkMxNi42NjY3IDkuNzkwODYgMTQuODc1OCA4IDEyLjY2NjcgOEMxMC40NTc2IDggOC42NjY3NSA5Ljc5MDg2IDguNjY2NzUgMTJDOC42NjY3NSAxNC4yMDkxIDEwLjQ1NzYgMTYgMTIuNjY2NyAxNloiIHN0cm9rZT0id2hpdGUiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPgo8cGF0aCBkPSJNMy42NjY3NSAxNlY4QzMuNjY2NzUgNS4yMzg1OCA1LjkwNTMzIDMgOC42NjY3NSAzSDE2LjY2NjdDMTkuNDI4MSAzIDIxLjY2NjcgNS4yMzg1OCAyMS42NjY3IDhWMTZDMjEuNjY2NyAxOC43NjE0IDE5LjQyODEgMjEgMTYuNjY2NyAyMUg4LjY2Njc1QzUuOTA1MzMgMjEgMy42NjY3NSAxOC43NjE0IDMuNjY2NzUgMTZaIiBzdHJva2U9IndoaXRlIi8+CjxwYXRoIGQ9Ik0xOC4xNjY3IDYuNTEwMTNMMTguMTc2NyA2LjQ5OTAyIiBzdHJva2U9IndoaXRlIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPC9zdmc+Cg=="
                          className="instagram-xKUELU decoded loaded" data-id="I16:230;16:290"
                          style={{height: '24px'}}/>
                      <p className="instagram-BsNnIH" data-id="I16:230;16:279">Instagram</p></div>
                    <div className="link-M2h0Me" data-id="I16:230;16:280">
                      <img
                          src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjUiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNSAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTE3LjQ4NTkgMjAuNzY4NEw0LjQxOTI1IDMuOTY4MzZDNC4xMTI3MiAzLjU3NDI1IDQuMzkzNTcgMyA0Ljg5Mjg2IDNINy4zNzI2M0M3LjU1Nzc4IDMgNy43MzI1NyAzLjA4NTQ4IDcuODQ2MjQgMy4yMzE2NEwyMC45MTI5IDIwLjAzMTZDMjEuMjE5NSAyMC40MjU4IDIwLjkzODYgMjEgMjAuNDM5MyAyMUgxNy45NTk2QzE3Ljc3NDQgMjEgMTcuNTk5NiAyMC45MTQ1IDE3LjQ4NTkgMjAuNzY4NFoiIHN0cm9rZT0id2hpdGUiLz4KPHBhdGggZD0iTTIwLjY2NjcgM0w0LjY2Njc1IDIxIiBzdHJva2U9IndoaXRlIiBzdHJva2UtbGluZWNhcD0icm91bmQiLz4KPC9zdmc+Cg=="
                          className="x-xTCtRr decoded loaded" data-id="I16:230;16:294" style={{height: '24px'}}/>
                      <p className="x-LI1jAy" data-id="I16:230;16:281">X</p></div>
                    <div className="link-YGDNEN" data-id="I16:230;16:282">
                      <img
                          src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjUiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNSAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTE3LjY2NjcgMkgxNC42NjY3QzEzLjM0MDYgMiAxMi4wNjg4IDIuNTI2NzggMTEuMTMxMiAzLjQ2NDQ3QzEwLjE5MzUgNC40MDIxNSA5LjY2Njc1IDUuNjczOTIgOS42NjY3NSA3VjEwSDYuNjY2NzVWMTRIOS42NjY3NVYyMkgxMy42NjY3VjE0SDE2LjY2NjdMMTcuNjY2NyAxMEgxMy42NjY3VjdDMTMuNjY2NyA2LjczNDc4IDEzLjc3MjEgNi40ODA0MyAxMy45NTk2IDYuMjkyODlDMTQuMTQ3MSA2LjEwNTM2IDE0LjQwMTUgNiAxNC42NjY3IDZIMTcuNjY2N1YyWiIgc3Ryb2tlPSJ3aGl0ZSIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+Cjwvc3ZnPgo="
                          className="frame-CEiRt3 decoded loaded" data-id="I16:230;16:297" style={{height: '24px'}}/>
                      <p className="facebook-CEiRt3" data-id="I16:230;16:283">Facebook</p></div>
                  </div>
                </div>
                <div className="form-L1miAN" data-id="I16:230;16:204">
                  <div className="text-lc5Ayq" data-id="I16:230;16:205">
                    <p className="newsletter-jvmazO" data-id="I16:230;16:206">Newsletter</p><p
                      className="be-the-first-to-know-about-our-sales-new-products-and-special-offers-jvmazO"
                      data-id="I16:230;16:207">Be the first to know about our updates</p></div>
                  <div className="frame-96-lc5Ayq" data-id="I16:230;16:208">
                    <div className="input-YxJyad" data-id="I16:230;16:209">
                      <p className="email-address-sevSpm" data-id="I16:230;16:210">Email Address</p>
                      <div className="input-sevSpm" data-id="I16:230;16:211">
                        <p className="enter-your-email-address-RyrmG1" data-id="I16:230;16:212">Enter your email
                          address</p></div>
                    </div>
                    <div className="button-YxJyad" data-id="I16:230;16:213">
                      <img
                          src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTMgMTJIMjFNMjEgMTJMMTIuNSAzLjVNMjEgMTJMMTIuNSAyMC41IiBzdHJva2U9IiM3NDE2QjQiIHN0cm9rZS13aWR0aD0iMS41IiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPC9zdmc+Cg=="
                          className="frame-PjhrFA decoded loaded" data-id="I16:230;16:214" style={{height: '24px'}}/>
                    </div>
                  </div>
                </div>
              </div>
              <div className="credits-wYjOk4" data-id="I16:230;16:216">
                <div className="divider-lDfZi0" data-id="I16:230;16:217">
                </div>
                <div className="row-lDfZi0" data-id="I16:230;16:218">
                  <p className="x-2024-spotya-AGU0kh" data-id="I16:230;16:219">© 2024 SpotYa</p>
                  <div className="footer-links-AGU0kh" data-id="I16:230;16:220">
                    <p className="privacy-policy-Diw57H" data-id="I16:230;16:221">Privacy Policy</p><p
                      className="terms-of-service-Diw57H" data-id="I16:230;16:222">Terms of Service</p></div>
                </div>
              </div>
            </div>
          </div>
          <div className="overlay-window" id="overlay-main-js">
          </div>
        </div>

</div>
        <DemoFooter/>
      </>
  );
}

export default LandingPage;
