import Accordion from "@mui/material/Accordion";
import Modal from "@mui/material/Modal";
import AccordionSummary from "@mui/material/AccordionSummary";
import KeyDown from "@mui/icons-material/KeyboardArrowDown";
import {FormattedMessage, useIntl} from "react-intl";
import AccordionDetails from "@mui/material/AccordionDetails";
import {WeeklyHours} from "../WeeklyHours";
import {MDDialog} from "../../../components/Dialog";
import React, {useEffect, useState} from "react";
import {Paper} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import MDButton from "../../../components/MDButton";

export const AvailabilityEditor = ({schedules, editorRef, tempEvents, setTempEvents, setSchedules, fetchSchedules, setExpanded,  expanded}) => {
    const [open, setOpen] = useState(true);

    useEffect(()=>{
        setOpen(true);
        return ()=>setOpen(false)
    }, [])

    const intl = useIntl()
    const handleClose = () => setExpanded(false);
    return (
        <Modal style={{marginTop: expanded?0:-100000, height: 'fit-content'}}
            hideBackdrop={!expanded}
            open={true}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Paper className={'px-5 pt-5 pb-3'} style={{maxHeight: '100vh', overflowY:'auto'}}>
                <IconButton onClick={handleClose} style={{position:'absolute', right:6, top: -2}}><CloseIcon color={'info'} fontSize={'large'}/></IconButton>
     <Accordion slotProps={{transition: {duration: 200}}} style={{borderRadius: 20, zIndex:2}} {...{expanded}} onChange={()=>setExpanded(exp=>!exp)}>
            <AccordionSummary
                expandIcon={
                    <KeyDown
                        fontSize={'large'}
                        color={'white'}
                    />
                }
                aria-controls="panel1-content"
                id="panel1-header"
                style={{
                    backgroundColor: '#1A73E8',
                    color: 'white',
                    borderRadius: 10
                }}
            >
                <FormattedMessage
                    id={"schedule.edit"}
                    defaultMessage={'Edit your availability'}
                />
            </AccordionSummary>
            <AccordionDetails style={{borderRadius: 10}}>
                <WeeklyHours
                    setExpanded={setExpanded}
                    fetchSchedules={fetchSchedules}
                    setSchedules={setSchedules}
                    schedules={schedules}
                    ref={editorRef}
                    tempEvents={tempEvents}
                    setTempEvents={setTempEvents}
                />
            </AccordionDetails>
        </Accordion>
                <div className={'text-center'}>
                    <MDButton color={'secondary'} onClick={handleClose} variant={'contained'}>Close</MDButton>
                </div>
            </Paper>
        </Modal>
    )
}
