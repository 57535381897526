/*!

=========================================================
* Paper Kit React - v1.3.2
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2023 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/
import React from "react";

// reactstrap components
import {Row, Container, Col} from "reactstrap";
import FavoriteIcon from '@mui/icons-material/Favorite';
import {useIntl} from "react-intl";
import {useAuth} from "../../useAuth";

function DemoFooter() {
    const intl = useIntl();

    const {user} = useAuth()
    return (
        <footer className="footer-new text-white">
            <Row className={'top-row'}>

                <Col xs={12} lg={4}>
                    <div className={'footer-logo'}>SpotYa</div>

                    <div className={'footer-sublogo'}>Spotya aims to make the process of finding and booking a sports coach simple and efficient for everyone.</div>
                </Col>
                <Col xs={12} lg={2}>
                    <div className={'footer-title'}>Navigation</div>

                    {!user?.isBusiness && <a href={'/search'}
                                             className={'footer-nav-link'}>{intl.formatMessage({id: 'pages.search'})}</a>}
                    {!user &&
                        <a href={'/about'} className={'footer-nav-link'}>{intl.formatMessage({id: 'pages.about'})}</a>}
                    {user && <a href={'/profile'}
                                className={'footer-nav-link'}>{intl.formatMessage({id: 'pages.profile'})}</a>}
                    {!user?.isBusiness && <a href={'/bookings'}
                                className={'footer-nav-link'}>{intl.formatMessage({id: 'pages.bookings.my'})}</a>}
                    {user && user.isBusiness && <a href={'/business/schedule'}
                                className={'footer-nav-link'}>{intl.formatMessage({id: 'pages.schedule'})}</a>}
                    {user && user.isBusiness && <a href={'/business/calendar'}
                                className={'footer-nav-link'}>{intl.formatMessage({id: 'pages.calendar'})}</a>}
                    <a href={'mailto:admin@spotya.lt'} className={'footer-nav-link'}>{intl.formatMessage({id: 'pages.support'})}</a>
                </Col>
                <Col xs={12} lg={2}>
                    <div className={'footer-title'}>Social Media</div>

                    <a href={'/'} className={'footer-nav-link'}>
                        <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M12.6667 16C14.8758 16 16.6667 14.2091 16.6667 12C16.6667 9.79086 14.8758 8 12.6667 8C10.4576 8 8.66675 9.79086 8.66675 12C8.66675 14.2091 10.4576 16 12.6667 16Z"
                                stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
                            <path
                                d="M3.66675 16V8C3.66675 5.23858 5.90533 3 8.66675 3H16.6667C19.4281 3 21.6667 5.23858 21.6667 8V16C21.6667 18.7614 19.4281 21 16.6667 21H8.66675C5.90533 21 3.66675 18.7614 3.66675 16Z"
                                stroke="white"/>
                            <path d="M18.1667 6.51013L18.1767 6.49902" stroke="white" strokeLinecap="round"
                                  strokeLinejoin="round"/>
                        </svg>
                        Instagram</a>
                    <a href={'/'} className={'footer-nav-link'}>
                        <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M17.6667 2H14.6667C13.3406 2 12.0688 2.52678 11.1312 3.46447C10.1935 4.40215 9.66675 5.67392 9.66675 7V10H6.66675V14H9.66675V22H13.6667V14H16.6667L17.6667 10H13.6667V7C13.6667 6.73478 13.7721 6.48043 13.9596 6.29289C14.1471 6.10536 14.4015 6 14.6667 6H17.6667V2Z"
                                stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                    Facebook</a>
            </Col>
            <Col xs={12} lg={1}>
                &nbsp;
            </Col>
            <Col className={'footer-newsletter'} xs={12} lg={3}>
                <div className={'footer-title mb-4'}>Newsletter</div>
                <p style={{fontSize: 14}}>Be the first to know about our updates</p>
                <div className={'footer-form mt-4'}>
                    <div style={{alignSelf: 'normal', marginTop: -6}}>
                        <label>Email Address</label>
                    </div>
                    <input type={'text'} placeholder={'Enter your email address'} />
                    <button>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3 12H21M21 12L12.5 3.5M21 12L12.5 20.5" stroke="#7416B4" strokeWidth="1.5"
                                  strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                    </button>
                </div>
            </Col>

            </Row>
            <Row className={'bottom-row'}>
                <Col xs={4} lg={6} className={'bottom-copy'}>© 2024 SpotYa</Col>
                <Col xs={8} lg={6} className={'text-right bottom-links'}>
                    <a href="/terms">
                        {intl.formatMessage({id: 'common.terms'})}
                    </a>
                    <a href="/policy">
                        {intl.formatMessage({id: 'common.privacy'})}
                    </a>
                </Col>
            </Row>
        </footer>
    );
}

export default DemoFooter;
