import { forwardRef } from "react";
import PropTypes from "prop-types";
import MDAvatarRoot from "components/MDAvatar/MDAvatarRoot";

const MDAvatar = forwardRef(
    ({ bgColor = "transparent", size = "md", shadow = "none", ...rest }, ref) => (
        <MDAvatarRoot ref={ref} ownerState={{ shadow, bgColor, size }} {...rest} />
    )
);

// Typechecking props for the MDAvatar
MDAvatar.propTypes = {
    bgColor: PropTypes.string,
    size: PropTypes.oneOf(["xs", "sm", "md", "lg", "xl"]),
    shadow: PropTypes.string,
};

export default MDAvatar;
