/**
=========================================================
* Material Dashboard 2 PRO React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Material Dashboard 2 PRO React components
import MDInput from "components/MDInput";

function FormField({ label=' ', component, ...rest }) {
  if(component) {
    return <div style={{position: 'relative'}}>
    <label className={'outlined-label'} style={{zIndex:1}}>{label}</label>
      {component}
    </div>
  }
  return (
    <MDInput
      variant="standard"
      label={label}
      fullWidth
      InputLabelProps={{ shrink: true }}
      {...rest}
    />
  );
}


// Typechecking props for FormField
FormField.propTypes = {
  label: PropTypes.string,
};

export default FormField;
