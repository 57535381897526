import * as React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Dialog from '@mui/material/Dialog';
import PersonIcon from '@mui/icons-material/Person';
import AddIcon from '@mui/icons-material/Add';
import Typography from '@mui/material/Typography';
import { blue } from '@mui/material/colors';
import Slide from '@mui/material/Slide';
import DialogActions from '@mui/material/DialogActions';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import {FormattedMessage} from "react-intl";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export function MDDialog(props) {
    const { open, title, content, onSave, onClose, size, saveButtonText, noWrapper=false } = props;

    const handleSave = () => {
        onSave();
    };
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    return noWrapper?(
        <Dialog
            classes={{paper: 'no-wrapper-dialog'}}
            open={open}
                fullScreen={fullScreen}
                onBackdropClick={onClose}
                maxWidth={size||'md'}
                fullWidth
                TransitionComponent={Transition}>{content}</Dialog>
        ) : (
            <Dialog open={open} fullScreen={fullScreen} onBackdropClick={onClose}
                maxWidth={size||'md'}
                fullWidth
                TransitionComponent={Transition}>
            <DialogTitle>{title}</DialogTitle>
            <IconButton
                aria-label="close"
                onClick={onClose}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <CloseIcon />
            </IconButton>
            <DialogContent dividers>
                {content}
            </DialogContent>
            <DialogActions>
                <Button autoFocus onClick={handleSave}>
                    {saveButtonText||<FormattedMessage id={"dialog.save.changes"}/>}
                </Button>
            </DialogActions>
        </Dialog>
    );
}
